import React, { useMemo, useEffect, useCallback } from "react"

import { useQuery } from "@apollo/client"
import {
  useUser,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react"

import { AddressType } from "@components/AddressRow"
import { notNullOrQuietError, unsafeCast } from "@utils/null"

import ls, { localKeys } from "@services/localStorage"

import { FetchSessionDocument, Language, ViewMode } from "@gql/graphql"

import SessionContext, {
  RoleDetails,
  SessionContextType,
} from "./SessionContext"

interface SessionProviderProps {
  children: React.ReactNode
}

const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}: SessionProviderProps) => {
  const { user, isLoaded: isLoadedUser } = useUser()
  const { organization, isLoaded: isLoadedOrganization } = useOrganization()

  // Use mostRecentOrg as a fallback if organization is not available
  // due to user signing in through username and password for the first time
  const mostRecentOrg =
    user?.organizationMemberships?.[user.organizationMemberships.length - 1]
      ?.organization

  const {
    isLoaded: isLoadedOrgLists,
    setActive,
    userMemberships,
  } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  })

  const { data, previousData, fetchMore } = useQuery(FetchSessionDocument, {
    variables: {
      clerkOrganizationId: notNullOrQuietError(
        organization?.id ?? mostRecentOrg?.id,
        "organization.id ?? mostRecentOrg.id",
      ),
    },
    skip: !(organization?.id || mostRecentOrg?.id),
    onCompleted: () => {
      const currentLanguage = ls.getItem(localKeys.LANGUAGE)
      const currentViewMode = ls.getItem(localKeys.VIEW_MODE)

      if (!currentLanguage) {
        ls.setItem(
          localKeys.LANGUAGE,
          data?.organizationMember?.settings?.language ?? Language.En,
        )
        window.location.reload()
      }

      if (!currentViewMode) {
        ls.setItem(
          localKeys.VIEW_MODE,
          data?.organizationMember?.settings?.viewMode ?? ViewMode.Dark,
        )
        window.location.reload()
      }
    },
    onError: () => {
      console.error("could not fetch this org's data")
    },
  })
  const sessionData = data ||
    previousData || {
      currentUser: null,
      organization: null,
      organizationMember: null,
    }

  const wrappedRefetch = useCallback(() => {
    if (!(organization?.id || mostRecentOrg?.id)) {
      return
    }
    const variables = {
      clerkOrganizationId: organization?.id ?? mostRecentOrg?.id,
    }
    fetchMore({ variables })
  }, [fetchMore, organization?.id, mostRecentOrg?.id])

  // Refetch if organization changes
  useEffect(() => {
    wrappedRefetch()
  }, [wrappedRefetch, organization?.id, mostRecentOrg?.id])

  // when user is signed up with just username, they need their org membership "activated" in Clerk
  useEffect(() => {
    if (
      isLoadedOrgLists &&
      !organization &&
      userMemberships?.data?.length > 0
    ) {
      setActive(userMemberships?.data[userMemberships.data.length - 1])
    }
  }, [setActive, isLoadedOrgLists, organization, userMemberships])

  const values = useMemo(() => {
    const newValues: SessionContextType = {
      clerkOrg: isLoadedOrganization ? (organization ?? mostRecentOrg) : null,
      clerkUser: isLoadedUser ? user : null,
      currentUser:
        sessionData.currentUser == null
          ? sessionData.currentUser
          : {
              ...sessionData.currentUser,
              id: sessionData.currentUser?.id,
              username: "",
            },
      loadedClerk: isLoadedOrganization && isLoadedUser,
      org:
        sessionData.organization == null
          ? sessionData.organization
          : {
              ...sessionData.organization,
              logo: sessionData.organization.logo ?? null,
              addresses: unsafeCast<AddressType[]>(
                sessionData.organization.addresses,
              ),
            },
      orgMember:
        sessionData.organizationMember == null
          ? sessionData.organizationMember
          : {
              ...sessionData.organizationMember,
              profile: {
                ...sessionData.organizationMember.profile,
                status: sessionData.organizationMember.profile.status ?? null,
                role: unsafeCast<RoleDetails>(
                  sessionData.organizationMember.profile.role,
                ),
                about: sessionData.organizationMember.profile.about ?? null,
                email: sessionData.organizationMember.profile.email ?? "",
                image: sessionData.organizationMember.profile.image ?? "",
                fullName: sessionData.organizationMember.profile.fullName ?? "",
                firstName:
                  sessionData.organizationMember.profile.firstName ?? "",
                lastName: sessionData.organizationMember.profile.lastName ?? "",
              },
              settings: {
                ...sessionData.organizationMember.settings,
                viewMode:
                  sessionData.organizationMember.settings?.viewMode ??
                  String(ViewMode.Dark),
                language:
                  sessionData.organizationMember.settings?.language ??
                  String(Language.En),
                starredFeatures: {
                  nodes: unsafeCast<
                    Array<{
                      id: string
                      name: string
                    }>
                  >(
                    sessionData.organizationMember.settings?.starredFeatures
                      ?.nodes,
                  ),
                },
              },
            },
      refetchData: wrappedRefetch,
      canViewReporting: sessionData.organization?.canViewReporting ?? false,
      canViewWorkspacePeople:
        sessionData.organization?.canViewWorkspacePeople ?? false,
      canViewWorkspaceSettings:
        sessionData.organization?.canViewWorkspaceSettings ?? false,
    }
    return newValues
  }, [
    isLoadedOrganization,
    isLoadedUser,
    organization,
    sessionData?.currentUser,
    sessionData?.organization,
    sessionData?.organizationMember,
    user,
    wrappedRefetch,
    mostRecentOrg,
  ])

  return (
    <SessionContext.Provider value={values}>{children}</SessionContext.Provider>
  )
}

export default SessionProvider
