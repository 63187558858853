import React, { useEffect } from "react"

import Box from "@mui/material/Box"

import Modal from "@components/Modal"

interface ReportBugModalProps {
  closeMenu?: () => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ReportBugModal: React.FC<ReportBugModalProps> = ({
  closeMenu,
  open,
  setOpen,
}: ReportBugModalProps) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          formId: "55995d52-6ca8-4f57-a4ee-80994d41658b",
          portalId: "22481218",
          region: "na1",
          target: "#reportBugHubspotForm",
        })
      }
    })
  }, [])

  return (
    <Modal
      open={open}
      otherClosingActions={closeMenu}
      setOpen={setOpen}
      sx={{ minWidth: "400px" }}
    >
      <Box
        sx={{
          minHeight: "400px",
          minWidth: "500px",
        }}
      >
        <div id="reportBugHubspotForm" />
      </Box>
    </Modal>
  )
}

export default ReportBugModal
