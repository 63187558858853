import * as React from "react"

import MuiDrawer from "@mui/material/Drawer"
import { styled, Theme, CSSObject } from "@mui/material/styles"

import { useAppDrawer } from "@providers/AppDrawerProvider"

import NavList from "./NavList"

export const drawerWidth = 190
export const closedDrawerWidth = 60

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.mode === "light" ? "#F9F8F8" : "#2a2929",
  borderRight: "none",
  overflowX: "hidden",
  padding: "0 12px 0 9px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  width: drawerWidth,
})

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.mode === "light" ? "#F9F8F8" : "#2a2929",
  borderRight: "none",
  overflowX: "hidden",
  padding: "0 12px 0 9px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: closedDrawerWidth,
})

const MiniDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxSizing: "border-box",
  flexShrink: 0,
  whiteSpace: "nowrap",
  width: drawerWidth,
  zIndex: 998,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const Drawer: React.FC = () => {
  const { open, toggleDrawer } = useAppDrawer()

  return (
    <MiniDrawer variant="permanent" open={open}>
      <NavList open={open} toggleDrawer={toggleDrawer} />
    </MiniDrawer>
  )
}

export default Drawer
