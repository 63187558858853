import React, { useEffect, useRef } from "react"

import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import LoadingButton from "@mui/lab/LoadingButton"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { useTranslation } from "react-i18next"

import ErrorBoundary from "@components/ErrorBoundary"

export type MenuOption = {
  action: () => void
  text: string

  disabled?: boolean
}

interface ModalProps extends DialogProps {
  children: React.ReactNode
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>

  // NOT REQUIRED
  additionalActions?: React.ReactNode
  additionalTitle?: React.ReactNode
  altClosingAction?: () => void
  buttonColor?: "primary" | "secondary"
  closingText?: string
  danger?: boolean
  disabled?: boolean
  fullWidth?: boolean
  loadingButton?: boolean
  mainCtaAction?: () => void
  mainCtaText?: string
  menuButtonOptions?: MenuOption[]
  noClose?: boolean
  otherClosingActions?: () => void
  preTitle?: boolean
  preventDefaultClosingAction?: boolean
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxCloseIcon?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  title?: string
  titleNode?: React.ReactNode
  topActions?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  additionalActions,
  additionalTitle,
  altClosingAction,
  children,
  sxCloseIcon,
  closingText,
  buttonColor = "primary",
  danger,
  disabled,
  fullWidth = false,
  loadingButton: loading,
  mainCtaAction,
  mainCtaText,
  menuButtonOptions,
  noClose = false,
  open,
  otherClosingActions,
  preTitle = false,
  preventDefaultClosingAction,
  setOpen,
  sx,
  title,
  titleNode,
  topActions,
}: ModalProps) => {
  const { t } = useTranslation()
  const descriptionElementRef = useRef<HTMLElement>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const triggerMainAction = () => {
    if (mainCtaAction) {
      mainCtaAction()
    }
  }

  const delayClosingModal = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e?.stopPropagation()

    if (altClosingAction) {
      // if alternate way of closing modal is passed in, use that
      altClosingAction()
      return
    }

    setTimeout(() => {
      if (otherClosingActions) {
        otherClosingActions()
      }

      if (!preventDefaultClosingAction) {
        setOpen(false)
      }
    }, 100)
  }

  return (
    <ErrorBoundary>
      <Dialog
        aria-describedby="scroll-dialog-description"
        aria-labelledby="scroll-dialog-title"
        data-testid="dialog"
        onClose={() => delayClosingModal()}
        open={open}
        scroll="paper"
        PaperProps={{ sx: { mt: "42px" } }}
        sx={{
          "& .MuiDialog-paperScrollPaper": {
            minWidth: fullWidth ? "calc(100% - 64px)" : "inherit",
            padding: "20px",
            ...sx,
          },
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
        }}
      >
        <IconButton
          data-testid="dialog-close-icon"
          onClick={(e) => delayClosingModal(e)}
          sx={{
            height: "40px",
            ml: "auto",
            position: "absolute",
            right: "12px",
            top: "12px",
            width: "40px",
            ...sxCloseIcon,
          }}
        >
          <CloseIcon />
        </IconButton>

        {(title || titleNode) && (
          <DialogTitle
            data-cy="itemDialog"
            id="scroll-dialog-title"
            sx={{
              alignItems: "center",
              display: "flex",
              p: 0,
              pb: 1,
              pr: "40px",
            }}
            variant="h4"
          >
            {titleNode ?? (
              <>
                {preTitle && additionalTitle && additionalTitle} {title}{" "}
                {!preTitle && additionalTitle && additionalTitle}
                {topActions && <Box sx={{ ml: "auto" }}>{topActions}</Box>}
              </>
            )}
          </DialogTitle>
        )}
        <DialogContent sx={{ p: 0 }}>{children}</DialogContent>

        <DialogActions
          sx={{
            p: 0,
            pt:
              // render no height if there are no actions
              !additionalActions &&
              !mainCtaText &&
              !menuButtonOptions &&
              noClose
                ? 0
                : 2,
          }}
        >
          {additionalActions && additionalActions}

          {!noClose && (
            <Button
              onClick={() => delayClosingModal()}
              size="small"
              data-cy="cancel"
              variant="outlined"
            >
              {closingText || t("cancel")}
            </Button>
          )}

          {menuButtonOptions ? (
            <>
              <Button
                disabled={disabled}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                size="small"
                variant="contained"
              >
                {mainCtaText}
              </Button>
              <Menu
                id="modal-main-cta-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "modal-main-cta-button",
                }}
              >
                {menuButtonOptions.map((option) => (
                  <MenuItem
                    dense
                    disabled={option.disabled}
                    key={option.text}
                    onClick={() => {
                      option.action()
                      handleClose()
                    }}
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            mainCtaText && (
              <LoadingButton
                color={danger ? "error" : buttonColor}
                disabled={disabled}
                loading={loading}
                onClick={triggerMainAction}
                size="small"
                variant="contained"
              >
                {mainCtaText}
              </LoadingButton>
            )
          )}
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  )
}

export default Modal
