import React from "react"

import { AddressType } from "@components/AddressRow"

export type CurrentUserType = {
  id: string
  username: string
}

export type OrganizationType = {
  id: string
  name: string
  logo: string | null
  addresses: AddressType[]
  defaultCurrency: {
    code: string
    id: string
    symbol: string
  }
  hasActiveAccountingIntegration: boolean
}

type SettingsData = {
  language: string
  starredFeatures: {
    nodes: Array<{
      id: string
      name: string
    }>
  }
  viewMode: string
}

export type RoleDetails = {
  id: string
  name: string
  displayName: string
  description: string | null
}

type ProfileData = {
  about: string | null
  email: string
  firstName: string | null
  fullName: string | null
  id: string
  image: string | null
  lastName: string | null
  role: RoleDetails
  status: string | null
}

export type OrgMemberType = {
  id: string | null
  settings: SettingsData
  profile: ProfileData
}

export type SessionContextType = {
  clerkOrg: any | null | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
  clerkUser: any | null | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
  currentUser: CurrentUserType | null | undefined
  loadedClerk: boolean
  org: OrganizationType | null | undefined
  orgMember: OrgMemberType | null | undefined
  refetchData: () => void
  canViewReporting: boolean
  canViewWorkspaceSettings: boolean
  canViewWorkspacePeople: boolean
}

const SessionContext = React.createContext<SessionContextType>({
  clerkOrg: null as any | null | undefined, // eslint-disable-line @typescript-eslint/no-explicit-any
  clerkUser: null as any | null | undefined, // eslint-disable-line @typescript-eslint/no-explicit-any
  currentUser: null as CurrentUserType | null | undefined,
  loadedClerk: false,
  org: null as OrganizationType | null | undefined,
  orgMember: null as OrgMemberType | null | undefined,
  refetchData: () => {},
  canViewReporting: false,
  canViewWorkspaceSettings: false,
  canViewWorkspacePeople: false,
})

export default SessionContext
