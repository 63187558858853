import * as React from "react"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { Outlet } from "react-router-dom"

import ProtectedView from "@components/ProtectedView"

import Drawer from "./Drawer"

const Layout: React.FC = () => {
  return (
    <ProtectedView>
      <Box sx={{ display: "flex" }}>
        <Drawer />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container sx={{ mt: 4, mb: 4, maxWidth: "inherit !important" }}>
            {/* Outlet renders the actual components */}
            <Outlet />
          </Container>
        </Box>
      </Box>
    </ProtectedView>
  )
}

export default Layout
