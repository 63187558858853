/* How to add a new route:

When a new route needs to be added in the TabsRouter and the Sidebar Drawer, 
you need to add the route in the following places:

- routes.ts (this file!)
- views/lazy.ts
- features.tsx (as a nav item and in the menu list)
- App.tsx
- TabsRouter.tsx (in the routeMatch function)

When adding sub routes (for tabs in modals or tabs under main tabs), 
you only need to add the route in these files:

- routes.ts (this file!)
- features.tsx (as a subnav and menu list)

*/

// SALES HUB
const SALES = "/sales"
const CUSTOMERS = `${SALES}/customers` // deprecated
const ORDERS = `${SALES}/orders`
const QUOTES = `${SALES}/quotes`
const PRODUCTS = `${SALES}/products` // deprecated
const salesRoutes = {
  CUSTOMERS,
  PRODUCTS,
  ORDERS,
  QUOTES,
}

// OPS HUB
const OPS = "/operations"
const INVENTORY = `${OPS}/inventory`
const ITEMS = `${OPS}/items`
const MATERIALS = `${OPS}/materials` // deprecated
const PRODUCTION = `${OPS}/production`
const RECEIVING = `${OPS}/receiving`
const SHIPPING = `${OPS}/shipping`
const VENDORS = `${OPS}/vendors` // deprecated
const WAREHOUSE = `${OPS}/warehouse`
const ITEM_TAB = "/:itemId/:tab"
const SHIPPING_TAB = "/:orderId/:tab"
const opsRoutes = {
  ITEM_TAB,
  ITEMS,
  INVENTORY,
  MATERIALS,
  PRODUCTION,
  RECEIVING,
  SHIPPING,
  SHIPPING_TAB,
  VENDORS,
  WAREHOUSE,
}

// Items sub routes
const ITEM_AUTOMATIONS = "automations"
const ITEM_DETAILS = "details"
const ITEM_INVENTORY = "inventory"
const ITEM_PRODUCTION = "production"
const ITEM_PRODUCTION_BOM = `${ITEM_PRODUCTION}/bom`
const ITEM_PRODUCTION_ROUTING = `${ITEM_PRODUCTION}/routing`
const ITEM_PRODUCTION_HISTORY = `${ITEM_PRODUCTION}/history`
const ITEM_PURCHASING = "purchasing"
const ITEM_SALES = "sales"
const ITEM_VARIANTS = "variants"
const ITEM_VENDORS = "vendors"
export const itemSubRoutes = {
  ITEM_AUTOMATIONS,
  ITEM_DETAILS,
  ITEM_INVENTORY,
  ITEM_PRODUCTION,
  ITEM_PRODUCTION_BOM,
  ITEM_PRODUCTION_ROUTING,
  ITEM_PRODUCTION_HISTORY,
  ITEM_PURCHASING,
  ITEM_SALES,
  ITEM_VARIANTS,
  ITEM_VENDORS,
}

// Receiving sub routes
const RCV_DETAILS = "details"
const RCV_GENERAL = "general"
export const receivingSubRoutes = {
  RCV_DETAILS,
  RCV_GENERAL,
}

// Inventory sub routes
const INVENTORY_DETAILS = "details"
const INVENTORY_ACTIVITY = "activity"
export const inventorySubRoutes = {
  INVENTORY_DETAILS,
  INVENTORY_ACTIVITY,
}

// Move inventory sub routes
const MOVE_INVENTORY_MOVE = "move"
const MOVE_INVENTORY_HISTORY = "history"
export const moveInventorySubRoutes = {
  MOVE_INVENTORY_MOVE,
  MOVE_INVENTORY_HISTORY,
}

// Job sub routes
const JOB_DETAILS = "details"
const JOB_PRODUCTION = "production"
const JOB_MATERIALS = "materials"
const JOB_QUALITY = "quality"
export const jobSubRoutes = {
  JOB_DETAILS,
  JOB_PRODUCTION,
  JOB_MATERIALS,
  JOB_QUALITY,
}

// Warehouse location sub routes
const WAREHOUSE_LOCATIONS_DETAILS = "details"
const WAREHOUSE_LOCATIONS_INVENTORY = "inventory"
export const warehouseLocationSubRoutes = {
  WAREHOUSE_LOCATIONS_DETAILS,
  WAREHOUSE_LOCATIONS_INVENTORY,
}

// Shipping sub routes
const SHIPPING_ORDERS = "orders"
const SHIPPING_SHIPMENTS = "shipments"
export const shippingSubRoutes = {
  SHIPPING_ORDERS,
  SHIPPING_SHIPMENTS,
}

// Shipping modal sub routes
const SHIPPING_PICK = "pick"
const SHIPPING_PACK = "pack"
const SHIPPING_SHIP = "ship"
export const shippingModalSubRoutes = {
  SHIPPING_PICK,
  SHIPPING_PACK,
  SHIPPING_SHIP,
}

// FINANCE HUB
const FINANCE = "/finance"
const PURCHASE_ORDERS = `${FINANCE}/purchase-orders`
const financeRoutes = {
  PURCHASE_ORDERS,
}

// PEOPLE HUB
const PEOPLE = "/people"
const COMPANIES = `${PEOPLE}/companies`
const CONTACTS = `${PEOPLE}/contacts`
const USERS = `${PEOPLE}/users`
const peopleRoutes = {
  COMPANIES,
  CONTACTS,
  USERS,
}

// REPORTING HUB
const REPORTING = "/reporting"
const REPORTS = `${REPORTING}/reports`
const reportingRoutes = {
  REPORTING,
  REPORTS,
}

const SIGN_IN = "/sign-in"
const SIGN_UP = "/sign-up"
const PROFILE_BUILDER = `${SIGN_UP}/profile-builder`
const authRoutes = {
  PROFILE_BUILDER,
  SIGN_IN,
  SIGN_UP,
}

const SETTINGS = "/settings"
const CUSTOM_DOCS = `${SETTINGS}/custom-docs`
const CUSTOM_FIELDS = `${SETTINGS}/custom-fields`
const CUSTOM_LABELS = `${SETTINGS}/custom-labels`
const INTEGRATIONS = `${SETTINGS}/integrations`
const OPERATIONS = `${SETTINGS}/operations`
const TOOLS = `${SETTINGS}/tools`
const WAREHOUSE_LOCATIONS = `${SETTINGS}/warehouse-locations`
const PERMISSIONS_AND_BILLING = `${SETTINGS}/permissions-and-billing`
const settingsRoutes = {
  CUSTOM_DOCS,
  CUSTOM_FIELDS,
  CUSTOM_LABELS,
  INTEGRATIONS,
  OPERATIONS,
  PERMISSIONS_AND_BILLING,
  SETTINGS,
  TOOLS,
  WAREHOUSE_LOCATIONS,
}

const ACCESS_DENIED = "/access-denied"
const DASHBOARD = "/scorecard"
const NO_ORGANIZATION = "/no-organization"
const NOT_FOUND = "/*"
const otherRoutes = {
  ACCESS_DENIED,
  DASHBOARD,
  NO_ORGANIZATION,
  NOT_FOUND,
}

const routes = {
  ...authRoutes,
  ...financeRoutes,
  ...opsRoutes,
  ...otherRoutes,
  ...peopleRoutes,
  ...salesRoutes,
  ...settingsRoutes,
  ...reportingRoutes,

  // Sub routes
  ...itemSubRoutes,
  ...shippingSubRoutes,
  ...shippingModalSubRoutes,
}

export default routes
