import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

import ls, { localKeys } from "@services/localStorage"

const getLanguageFromLocalStorage = (): string => {
  return ls.getItem(localKeys.LANGUAGE) || "en" // Default to "en" if not set
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: getLanguageFromLocalStorage(),
    ns: ["translation", "quotes", "tools", "components"],
    defaultNS: "translation",
    saveMissing: true,
  })

export default i18n
